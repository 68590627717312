import React, { useEffect, useState } from 'react'
import MemberLayout from '../../../layouts/MemberLayout'
import { Box, Title, Grid, Text, Badge, Flex, Card, Skeleton, Image, Button, Modal, ActionIcon } from '@mantine/core'
import { getUserProgramList } from '../../../services/userPrograms'
import classes from '../MemberPage.module.css'
import { useNavigate } from 'react-router-dom'
import NoImage from '../../../assets/img/no-image.png'
import { getImageFile } from '../../../services/fileImage'
import { modals } from '@mantine/modals'
import FormFeedbackProgram from '../../../components/pages/Member/Program/FormFeedbackProgram'
import { IconAlertTriangleFilled } from '@tabler/icons-react'

const defaultParams = {
  kind: ''
}

const eventType = [
  {
    label: 'Semua',
    value: ''
  },
  {
    label: 'Training',
    value: 'training'
  },
  {
    label: 'Bootcamp',
    value: 'bootcamp'
  },
]

const Program = () => {
  const navigate = useNavigate()
  const [dataProgram, setDataProgram] = useState([])
  const [ params, setParams ] = useState(defaultParams)
  const [ loading, setLoading ] = useState(true)
  const [modalFeedback, setModalFeedback] = useState(false)
  const [completionId, setCompletionId] = useState(null)

  const handleProgramUser = async () => {
    setLoading(true)
    try {
      const response = await getUserProgramList(params)
      setDataProgram(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleProgramUser()
    // eslint-disable-next-line
  }, [params])

  const onCloseModalFeedback = () => {
    setModalFeedback(false)
    setCompletionId(null)
  }

  const mappingTypeEvent = (type) => type.map((val, index) => {
    return (
      <Badge color='red' size='lg' key={index} variant={params.kind === val.value ? 'filled' : 'outline'} onClick={() => handleSetParams(val.value)} style={{ cursor: 'pointer' }}>{val.label}</Badge>
    )
  })

  const handleSetParams = (e) => {
    // eslint-disable-next-line
    setParams({ ...params, ['kind']: e })
  }

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Grid.Col span={{ base: 12, lg: 3 }} key={i}>
        <Card shadow='sm' padding='lg' radius='md' withBorder>
          <Card.Section>
            <Skeleton height={400} width="100%" />
          </Card.Section>
        </Card>
      </Grid.Col>
    )
  })

  const mappingDataProgram = (data) => {
    const openUrl = (dataCertificate) => {
      const fileCertificate = dataCertificate.certificate.file
      const feedbackMember = dataCertificate.userComment

      if (feedbackMember === null) {
        const handleOpenFeedback = () => {
          setModalFeedback(true)
          modals.closeAll()
          setCompletionId(dataCertificate.id)
        }

        return modals.openConfirmModal({
          centered: 'true',
          closeOnConfirm: false,
          children: (
            <Box>
              <Box ta='center'>
                <ActionIcon variant='transparent' color='red' size={120}>
                  <IconAlertTriangleFilled stroke={1.5} size={120} />
                </ActionIcon>
                <Text fw={500} mb={4}>Sebelum Klaim Sertifikat, Silahkan Isi Feedback Terlebih Dahulu </Text>
                <Text fz='xs' c='gray.6' mb={20}>Tolong diperhatikan. anda bisa mengklaim sertifikat anda setelah anda mengisi feedback dari program ini.</Text>
              </Box>
            </Box>
          ),
          labels: { confirm: 'Iya', cancel: "Tidak" },
          confirmProps: { color: 'red' },
          onConfirm: () => handleOpenFeedback(),
        })
      } else {
         // eslint-disable-next-line
        window.open(`${process.env.REACT_APP_SERVER_API_URL}/files/storage/${fileCertificate}`, '_blank')
      }
    }
    if (data.length > 0) {
      const remap = data.map((val, index) => {
        return (
          <Grid.Col span={{ base: 12, lg: 3 }} key={index}>
            <Card className={classes.programCard} shadow="sm" radius="lg" withBorder>
              <Card.Section>
                <Image src={val.eventSchedule.event.eventImages.length > 0 ? getImageFile(val.eventSchedule.event.eventImages[0].filepath) : NoImage} />
              </Card.Section>
              <Box className={classes.contentCard}>
                <Badge variant='gradient' size='xs' mb='xs' gradient={{ from: 'violet', to: 'red', deg: 90 }}>{val.eventSchedule.event.type}</Badge>
                <Text fw='bold' fz={14} lineClamp={2} h={45}>{val.eventSchedule.event.name}</Text>
                <Box mt={6}>
                  <Flex justify='space-between'>
                    <Text fw='500' fz={12} lineClamp={2}>Jadwal Program:</Text>
                    <Text fw='600' fz={12} lineClamp={1}>{val.eventSchedule.name}</Text>
                  </Flex>
                </Box>
                <Box mt={6}>
                  <Flex justify='space-between'>
                    <Text fw='500' fz={12} lineClamp={2}>Progress Program:</Text>
                    <Text fw='600' fz={12} lineClamp={1}>{val.summary.progressPercentage ? `${val.summary.progressPercentage} %` : '-'}</Text>
                  </Flex>
                </Box>
              </Box>
              <Box mt={10} mb={4}>
                <Button size='xs' color='red.6' fullWidth mb={4} onClick={() => navigate(`/program/${val.id}`)}>Detail</Button>
                <Button size='xs' fullWidth color={val.userEventCompletion === null ? 'gray' : 'green'}  onClick={val.userEventCompletion === null ? null : () => openUrl(val.userEventCompletion)}>{val.userEventCompletion === null ? 'Sertifikat Belum Tersedia' : 'Download Sertifikat'}</Button>
              </Box>
            </Card>
          </Grid.Col>
        )}
      )
      return remap
    } else {
      return (
        <Grid.Col span={12}>
          <Box ta='center' py={120}>
            <Text c="dark">Anda belum mengikuti {params.kind === '' ? 'program' : params.kind} apapun</Text>
          </Box>
        </Grid.Col>
      )
    }
  }
  
  return (
    <MemberLayout>
      <Box p={12}>
        <Title className={classes.titleHeader}>Program Saya</Title>
        <Box my={16}>
          <Flex gap='xs'>
            {mappingTypeEvent(eventType)}
          </Flex>
        </Box>
        <Box mt={30}>
          <Grid gutter='xl' className={classes.gridProgramCard} align="center">
            {loading ? loadingData(4) : mappingDataProgram(dataProgram)}
          </Grid>
        </Box>
      </Box>
      <Modal opened={modalFeedback} onClose={onCloseModalFeedback} centered closeOnClickOutside={false} size='xl' title={<Text fw='Bold'>Feedback Training</Text>} withCloseButton={false}>
        <FormFeedbackProgram userCompletionId={completionId !== null ? completionId : null} onCloseModal={onCloseModalFeedback} reloadDetail={handleProgramUser} />
      </Modal>
    </MemberLayout>
    
  )
}

export default Program